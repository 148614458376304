export function getTodayDateUpToHour() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");

//   console.log(`${year}-${month}-${day} ${hour}:00`);
  return `${year}-${month}-${day} ${hour}:00`;
}
