import React, { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";
import eventApi from "../../api/events";
import ticketsApi from "../../api/tickets";
import Loader from "../loader";
import SignUpCustomer from "./SignUpCustomer";
import { GrFormClose } from "react-icons/gr";
import Notice from "../notice";
import SelectTickets from "./SelectTickets";
import Modal from "../Modal";
import { getTodayDateUpToHour } from "../../utils/dateTime";
import { sha1, sha256, sha384, sha512 } from "crypto-hash";

function Summary({ toggleMenu }) {
  const [tickets, setTickets] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [warningShot, setWarningShot] = useState(false);
  //
  const [ticketCart, setTicketCart] = useState({});
  const [finalPrice, setFinalPrice] = useState(0);
  const [paspotCut, setPaspotCut] = useState(0);
  //
  const [customer, setCustomer] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [reservationId, setReservationId] = useState("");
  const timeStamp = getTodayDateUpToHour();
  // const publicKey = "pk_test_b4ba53990ba9bb45c9f4c824e346ca0dc367eaa5";
  const publicKey = "pk_live_c939468d2a83ccffe68a4240ab1d4e2b441f6bab";
  const navigate = useNavigate();

  const toggleWarning = () => {
    setWarningShot(!warningShot);
  };

  // FETCH EVENT DATA
  useEffect(() => {
    const fetchEventData = async () => {
      const queryString =
        window.location.href.split("buyticket/")[1] ||
        window.location.href.split("priceconfirm/")[1];
      const eventResponse = await eventApi.getEventUser(queryString);
      if (!eventResponse.ok) return console.log(eventResponse);
      setEventDetails(eventResponse.data);

      const customer = window.localStorage.getItem("email");
      // const phoneNumber = window.localStorage.getItem("phoneNumber");
      // const fullName = window.localStorage.getItem("name");
      if (customer) {
        setSignedIn(true);
        setCustomer(customer);
      }
    };
    fetchEventData();
  }, []);

  // FETCH TICKET DATA
  useEffect(() => {
    if (!eventDetails) return;
    const fetchTickets = async () => {
      const result = await ticketsApi.getTickets(
        eventDetails.eventName,
        eventDetails.eventOwner
      );
      if (!result.ok) return console.log(result);
      setTickets(result.data);
    };
    fetchTickets();
  }, [eventDetails]);

  // CALCULATE PRICES AND SHI
  useEffect(() => {
    const computePrice = () => {
      let userFeeSplit = 100 - eventDetails.feeSplit;
      userFeeSplit = 7 * (userFeeSplit / 100);
      let final = 0;
      Object.values(ticketCart).forEach((ticket) => {
        final += ticket.price * ticket.number;
      });
      setPaspotCut(percentage(userFeeSplit, final) + 100);
      setFinalPrice(final);
      hash();
    };
    if (eventDetails && ticketCart) computePrice();
  }, [ticketCart, eventDetails]);

  const percentage = (per, num) => {
    return (per / 100) * num;
  };

  const hash = () => {
    const cartString = JSON.stringify(Object.values(ticketCart));
    let result = sha256(`${customer} ${cartString} ${timeStamp}`).then(
      (hash) => {
        console.log("Hash", hash);
        setReservationId(hash);
      }
    );
  };

  //ONCHANGE EVENT FOR THE SELECT
  const handleOnChange = (e, ticketName) => {
    const ticketNum = parseInt(e.target.value);
    const ticketClass = tickets.find(
      (ticket) => ticketName === ticket.oneTicketClass.ticketName
    );
    if (!ticketClass) return;

    const { price, _id: ticketId } = ticketClass.oneTicketClass;
    const cartCopy = { ...ticketCart };
    if (ticketNum === 0) {
      delete cartCopy[ticketName];
    } else {
      cartCopy[ticketName] = { id: ticketId, number: ticketNum, price };
    }
    setTicketCart(cartCopy);
    // console.log(ticketCart);
  };

  const resetFinalPrice = () => {
    setFinalPrice(0);
  };

  // BUY TICKET FUNCTION - PAID
  const copTickets = async () => {
    setLoading(true);
    try {
      for (let ticketName in ticketCart) {
        const ticket = ticketCart[ticketName];
        for (let i = 0; i < ticket.number; i++) {
          const response = await ticketsApi.buyTicket(
            ticket.id,
            customer,
            reservationId
          );
          if (!response.ok) throw new Error("Error Occured");
        }
      }
      alert("Congrats you copped your tickets");
      navigate("/tickets");
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  // PAYSTACK FUNCTION
  const componentProps = {
    email: customer,
    metadata: {
      name: fullName,
      phone: phoneNumber,
      custom_fields: [
        {
          display_name: "Ticket Cart",
          variable_name: "ticket_cart",
          value: JSON.stringify(Object.values(ticketCart)),
        },
        {
          display_name: "Reservation ID",
          variable_name: "reservation_id",
          value: reservationId,
        },
      ],
    },
    amount: (finalPrice + paspotCut) * 100,
    publicKey,
    text: `Pay Now`,
    onSuccess: () => copTickets(),
    onClose: () => setWarningShot(!warningShot),
  };

  // BUY TICKET FUNCTION - FREE
  const handleOnTicketFree = async () => {
    if (Object.keys(ticketCart).length === 0) {
      alert("Please select a ticket to purchase");
    } else {
      copTickets();
    }
  };

  if (!tickets || loading) {
    return <Loader />;
  }

  console.log(finalPrice);

  return (
    <div>
      <Modal
        title="EGBON WHY YOU DEY CANCEL?"
        state={warningShot}
        toggle={toggleWarning}
      >
        <div className="flex flex-col gap-6">
          <img
            src="https://ik.imagekit.io/noah/Rectangle%2010.png?updatedAt=1716796760567"
            alt="man way too close to a camera"
            className="w-full h-40 object-cover rounded-md"
          />
          <span className="text-sm md:text-base">
            Did you encounter any issues trying to cop your ticket or did you
            change your mind about getting the ticket abi na mistake (be like
            say na mistake). If its the first one, you can try a different
            payment method or contact us to help you out
          </span>
          <div className="grid grid-cols-2 gap-4">
            <a
              href="https://wa.me/+14255311259"
              target="blank"
              className="btn btn-primary font-medium normal-case"
            >
              Contact Us
            </a>
            <button
              onClick={toggleWarning}
              className="btn btn-outline font-medium normal-case"
            >
              Try again
            </button>
          </div>
        </div>
      </Modal>
      {!signedIn && (
        <>
          <Notice
            toggleMenu={toggleMenu}
            tickets={tickets}
            handleOnChange={handleOnChange}
            finalPrice={finalPrice}
            buttonLoading={buttonLoading}
            handleOnTicketFree={handleOnTicketFree}
            componentProps={componentProps}
          />
        </>
      )}
      {signedIn && (
        <SelectTickets
          tickets={tickets}
          handleOnChange={handleOnChange}
          finalPrice={finalPrice}
          buttonLoading={buttonLoading}
          handleOnTicketFree={handleOnTicketFree}
          componentProps={componentProps}
          resetFinalPrice={resetFinalPrice}
        />
      )}
    </div>
  );
}

export default Summary;
