import { FaRegCopy } from "react-icons/fa";
import { HiExternalLink, HiLink } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCalendarCheck, FaLink } from "react-icons/fa";
import { RiMapPin2Line } from "react-icons/ri";

export default function OverviewEvent({
  ticketsSold,
  salesRevenue,
  image,
  formattedLinkName,
  name,
  handleOnPressDelete,
  copyToClipboard,
  url,
  eventDate,
  eventLocation,
  _id,
  eventName,
}) {
  const navigate = useNavigate();
  return (
    <div className='space-y-6'>
      <div className='flex items-center justify-between'>
        <h2 className='font-bold text-2xl text-left'>{name}</h2>
        <a
          href={`https://paspot.ng/buyticket/${formattedLinkName}`}
          target='blank'
          className='btn btn-outline btn-sm normal-case'
        >
          <HiExternalLink />
          Visit
        </a>
      </div>

      <img
        src={image}
        alt='Event Poster'
        className='h-52 object-cover rounded-lg w-full'
      />
      <div className='join w-full'>
        <input
          className='w-full join-item bg-none input border-black rounded-lg focus:outline-none '
          type='text'
          value={url}
          id='myInput'
        />
        <button
          onClick={copyToClipboard}
          className='btn btn-neutral join-item normal-case'
        >
          <FaRegCopy size={18} /> Copy
        </button>
      </div>

      <div className='border-2 border-black shadow-mobile rounded-lg p-4 space-y-6'>
        <h1 className='font-bold'>Date & Location</h1>

        <div className='space-y-4'>
          <div className='flex items-center gap-4'>
            <FaRegCalendarCheck /> <p>{eventDate}</p>
          </div>
          <div className='flex items-center gap-4'>
            <RiMapPin2Line /> <p>{eventLocation}</p>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <Link
            to={"/eventtest?" + _id + "?" + eventName}
            className='btn btn-outline btn-block normal-case'
          >
            Edit
          </Link>

          <button
            onClick={handleOnPressDelete}
            className='btn btn-outline btn-block normal-case'
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
